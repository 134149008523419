import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import Layout from "components/layout";
import Modal from "components/popup";
import SEO from "components/seo";
import GetInTouch from "components/getInTouch";

const Industries = (props) => {
  const [open, setOpen] = useState(false);
  const {
    pageContext: {
      data: { data },
    },
  } = props;

  return (
    <Layout>
      <SEO
        title={data.seo_project_title.replace(/<br>/g, "")}
        description={data.seo_project_meta_desc}
        keywords={data.seo_project_meta_keywords}
        slug={data.slug}
      />
      <Modal open={open} setOpen={setOpen} />
      <div
        className={data.Shorturl + " text-white font-primary text-2xl h-full"}
      >
        <div
          className={
            "bg-" +
            data.Shorturl +
            "-bg-image bannerImage bg-no-repeat bg-cover bg-center h-560 2xl:h-560"
          }
        >
          <div className="relative top-24 2xl:top-56 ml-0 pl-4 xl:ml-64 2xl:ml-416 laptop:ml-80">
            <div className="w-full max-w-max h-4 sm:h-4 md:h-8 mb-32">
              <h5
                className="text-3xl lg:text-6xl inline-block font-medium w-full font-riot"
                dangerouslySetInnerHTML={{ __html: data.title_x }}
              />
            </div>
            <div className="mt-12 clearfix">
              <div className="grid grid-cols-1 py-6 space-y-6 text-center md:flex md:flex-row md:align-baseline md:items-center md:space-x-6 md:space-y-0 font-opensans">
                <Link
                  to="/quote"
                  className="fr-btn1 uppercase rounded-full py-3 bg-orange text-gray text-sm cursor-pointer w-60  font-semibold font-primary"
                >
                  Get A Free Quote
                </Link>
                <a
                  href="/contactus.php"
                  className="fr-btn2 uppercase rounded-full bg-white py-3 text-black text-sm  cursor-pointer w-60 font-semibold font-primary"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: data.seo_project_content }}
        className=""
      />
      <GetInTouch />
    </Layout>
  );
};

export default Industries;
